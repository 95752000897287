#searchForm {

}

/* Headers */
#searchForm .searchLabel {
    padding-top: calc(0.375rem + 1px);
}

#searchForm .form-row{
    padding-top: 2px;
}