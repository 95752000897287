// Override default variables before the import
$body-bg: #fdfdfd;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Custom styles
h3 {
  .test-results {
    margin-bottom: 20px;
  }
}
